<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Briefing</strong>
          </CCardHeader>
          <CRow>
            <CCol md="8">
              <CCardBody>
                <CForm>
                  <div v-if="mode.newMode">
                    <MultiSelect
                      label="Models"
                      :options="allModels"
                      @input="updateModels"
                      :hide-selected="false"
                      :searchable="true"
                      optionLabel="modelName"
                      :disabled="mode.viewMode"
                      track-by="modelId"
                      :track-ids.sync="modelId"
                      idParamName="modelId"
                      :itemTypeisString="false"
                    ></MultiSelect>
                  </div>
                  <div>
                    <MultiSelect
                      label="Checklist"
                      :options="allChecklist"
                      @input="updateModels"
                      :hide-selected="false"
                      :searchable="true"
                      optionLabel="checkListName"
                      :disabled="mode.viewMode"
                      track-by="checklistId"
                      :track-ids.sync="job.checklistId"
                      idParamName="checklistId"
                      :itemTypeisString="false"
                    ></MultiSelect>
                    <CInput
                      label="Crane Number "
                      v-model="job.craneNo"
                      horizontal
                      :disabled="mode.viewMode"
                    />
                    <CInput
                      label="Job Number"
                      v-model="job.jobNo"
                      horizontal
                      :disabled="mode.viewMode"
                    ></CInput>
                    <!-- <CInput
                      label="Rema"
                      v-model="job.range"
                      horizontal
                      :disabled="mode.viewMode"
                    > -->
                    <div class="form-group row">
                      <label
                        for="example-text-input text-right"
                        class="col-sm-3 col-form-label"
                        >Job Range</label
                      >
                      <div class="col-sm-9">
                        <select
                          class="form-control"
                          v-model="job.statusRange"
                          :disabled="mode.viewMode"
                        >
                          <option value="0" selected>Normal</option>
                          <option value="1">Urgent</option>
                          <option value="2">Super Urgent</option>
                        </select>
                      </div>
                    </div>
                    <!-- <span v-if="mode.newMode||mode.editMode" style="color:red;">*</span>  -->
                    <!-- </CInput> -->
                  </div>
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="warning"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" /> Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="Save()"
            >
              <CIcon name="cil-check-circle" /> Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import datetime from "vuejs-datetimepicker";
import MultiSelect from "../../containers/MultiSelect";

import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "NewJob",
  components: {
    MultiSelect,
    datetime,
  },
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },

      job: {
        modelId: "",
        checklistId: "0",
        checkListName: "",
        craneNo: "",
        jobNo: "",
        statusRange: "0",
      },
      checkListName: job.checkListName,
      statusRange: "",
      modelId: "",
      checklistId: "",
      allModels: [],
      selectedChecklistId: [],
      allChecklist: [],
      updatedChecklist: [],

      horizontal: { label: "col-3", input: "col-9" },
    };
  },

  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;
      this.getModels();
      this.getChecklist();
    }
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;
      // this.briefingId = this.$route.params.briefingId;
      this.getModels();
      this.getChecklist();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;
      // this.briefingId = this.$route.params.briefingId;
      this.getModels();
      this.getChecklist();
    }
  },
  methods: {
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      this.$router.push({ name: "Briefings" });
    },
    updateModels(data) {
      var self = this;
      console.log("data from group", data);
      self.modelId = data.modelId;
      console.log(self.modelId);
      self.modelId = data.modelId;
      var model = self.allChecklist.filter(function (myId) {
        return data.modelId == myId.modelId;
      });
      self.model = [];
      console.log(model);
      self.allChecklist = model;
    },

    Save() {
      this.apiPostSecure(
        process.env.VUE_APP_API_HOST + "/emapp/web/secure/save/newJob",
        this.job
      ).then((job) => {
        console.log(JSON.stringify(job));

        this.$router.push({
          name: "job",
        });
      });
      // this.$v.job.$touch();
      // if (this.$v.job.$invalid) {
      //   console.log("form is invalid", JSON.stringify(this.$v.job));
      // } else {
      // fetch(
      //   process.env.VUE_APP_API_HOST + "/emapp/web/secure/save/newJob",

      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-type": "application/json",
      //     },
      //     body: JSON.stringify(this.job),
      //   },
      //   console.log("job data", this.job)
      // );
      // window.history.back();
      // // }
    },

    // Save() {
    //   this.$v.briefing.$touch();
    //   console.log("viewSelected brief topics", this.viewselectedBriefTopics);

    //   if (this.$v.briefing.$invalid) {
    //     console.log("form is invalid", JSON.stringify(this.$v.briefing));
    //   } else {
    //     // var urlForSave =
    //     //   process.env.VUE_APP_API_HOST + "/emapp/web/secure/save/briefing";

    //     // console.log("Save():url", urlForSave) .briefing.selectedTopics
    //     this.briefing.selectedTopics.topicId =
    //       this.viewselectedBriefTopics.forEach((element) => {
    //         console.log("elemment:::", element);
    //         this.briefing.selectedTopics.topicId = element;
    //         console.log(
    //           "new briefing creation",
    //           this.briefing.selectedTopics.topicId
    //         );
    //         //this.newElement.push(element);
    //         //console.log("new element", this.newElement);

    //         // const data = this.briefing.selectedTopics;
    //         // this.briefing["selectedTopics"].assign({ topicId }, this.newElement);
    //         // const object = data.assign({}, this.newElement);
    //         // const newObject = object.assign({}, this.newElement);
    //         // console.log("new Object", this.object);
    //         const data = this.briefing;
    //         // console.log("data", data);
    //         data.topicId.push(this.newElement);
    //         console.log("data", data.topicId);
    //         const newData = data.selectedTopics.topicId;
    //         console.log("newData", newData);
    //         // newData.push(newElement);
    //         // console.log("new data", newData);
    //         // data.selectedTopics.push(this.newElement);
    //         // console.log("data", data.selectedTopics);
    //         // this.briefing.selectedTopics["topicId"].push(this.newElement);
    //         // console.log(
    //         //   "brief id",
    //         //   this.briefing.selectedTopics["topicId"].push(this.newElement)
    //         // );
    //         // console.log(
    //         //   "brief",
    //         //   this.briefing.selectedTopics[0].topicId.push(this.newElement)
    //         // );
    //         // console.log("briefing topicid", this.briefing.selectedTopics.topicId);
    //       });
    //     // console.log("briefing element after foreach", this.newElement);
    //     console.log("this.briefing ::::", JSON.stringify(this.briefing));

    //     this.apiPostSecure(
    //       process.env.VUE_APP_API_HOST + "/emapp/web/secure/save/briefing",
    //       this.briefing
    //     ).then((post) => {
    //       console.log("post values", post);
    //       // this.$router.push({ name: "Briefings" });
    //     });
    //     // this.apiPostSecure(
    //     //   process.env.VUE_APP_API_HOST + "/emapp/web/secure/save/briefing",
    //     //   this.briefing
    //     // ).then((briefing) => {
    //     //   //    console.log(JSON.stringify(briefing));

    //     //   this.$router.push({
    //     //     name: "Briefings",
    //     //   });
    //     // });
    //   }
    // },
    getModels() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/all/models"
      )
        .then((response) => response.json())
        .then((data) => (this.allModels = data));
    },
    getChecklist() {
      console.log("model id", this.modelId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/echecklists"
      )
        .then((response) => response.json())
        .then(
          (data) => ((this.allChecklist = data), console.log(this.checklistId))
        );
    },
    getBriefNames() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/all/BriefNames"
      )
        .then((response) => response.json())
        .then((data) => (this.allBriefNames = data));
    },
    getBriefTopics() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/all/topics"
      )
        .then((response) => response.json())
        .then((data) => (this.allBriefTopics = data));
    },
    getDepartments() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/all/departments"
      )
        .then((response) => response.json())
        .then(
          (data) => (this.allDepartments = data),
          console.log("all department users", this.allDepartments)
        );
    },

    topicdetails({ topicName }) {
      return `${topicName}`;
      //return `${couponName} | ${couponCode} `;
    },
    officerdetails({ firstName, lastName }) {
      //return `${firstName} ${lastName}`;
      return `${firstName}  ${lastName}`;
    },

    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getUser() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/briefing/" +
          this.briefingId
      )
        .then((response) => response.json())
        // .then((data) => (this.briefing = data));
        .then((data) => {
          this.briefing = data;
          data.selectedTopics.forEach((element) => {
            this.viewselectedBriefTopics.push(element.topicId);
          });

          console.log("selected topics", this.data.selectedBriefTopics);
          console.log("element::::", this.element);
        });
    },

    // getBriefings() {
    //   fetch(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/topics")
    //     .then((response) => response.json())
    //     .then((data) => (this.allTopics = data));
    // },

    getOfficers() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/all/users?authority=ROLE_OFFICER"
      )
        .then((response) => response.json())
        .then((data) => (this.allOfficers = data));
    },
  },
  mounted() {
    //this.getBriefings();
    this.getModels();
    this.getChecklist();
    this.getOfficers();
    // this.getGroups();
    // this.getSubGroups();
    this.getBriefNames();
    this.getBriefTopics();
    this.getDepartments();
  },
};
</script>
